import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  DocumentTextIcon, 
  ArrowUpTrayIcon,
  RocketLaunchIcon,
  SparklesIcon,
  BoltIcon,
  ShieldCheckIcon,
  CpuChipIcon,
  ClipboardDocumentCheckIcon,
  GlobeAltIcon,
  AcademicCapIcon,
  BuildingOfficeIcon,
  DocumentCheckIcon,
  BeakerIcon,
  LightBulbIcon,
  BriefcaseIcon,
  IdentificationIcon,
  DocumentMagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import Navbar from '../navbar/Navbar';
import Testimonials from '../testimonials/Testimonials';
import { Link, animateScroll } from 'react-scroll';

// Helper Components
const DecorativeCircle = () => (
  <div className="absolute right-0 top-0 overflow-hidden w-full h-full -z-10">
    <div className="absolute -right-1/4 -top-1/4 w-[800px] h-[800px] rounded-full bg-gradient-to-br from-blue-50 via-blue-100 to-transparent opacity-50" />
  </div>
);

const StatCard = ({ icon: Icon, label, value }) => (
  <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
    <div className="flex items-center space-x-3">
      <Icon className="h-8 w-8 text-blue-600" />
      <div>
        <p className="text-2xl font-bold text-gray-900">{value}</p>
        <p className="text-sm text-gray-600">{label}</p>
      </div>
    </div>
  </div>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div 
    whileHover={{ scale: 1.02 }}
    className="bg-white p-6 rounded-xl shadow-md border border-gray-100"
  >
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        <Icon className="h-8 w-8 text-blue-600" />
      </div>
      <div>
        <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
        <p className="mt-2 text-gray-600 text-sm">{description}</p>
      </div>
    </div>
  </motion.div>
);

export default function AtsChecker({ onContactFormOpen }) {
  // State Management
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingText, setLoadingText] = useState("");

  const ScrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 800,
      smooth: "easeInOutQuart",
    });
  };

  // File Handling
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "application/pdf" || 
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select a PDF or DOCX file.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    ScrollToTop();
    setIsLoading(true);
    
    const formData = new FormData();
    formData.append("resume", selectedFile);

    try {
      const totalDuration = 20000;
      const interval = 200;
      const totalIntervals = totalDuration / interval;
      let i = 0;

      const apiRequest = fetch("/resumes/upload", {
        method: "POST",
        body: formData,
      });

      const simulateProgress = () => {
        if (i <= totalIntervals) {
          const progress = Math.round((i / totalIntervals) * 100);
          setLoadingProgress(progress);
          
          if (progress <= 25) {
            setLoadingText("Uploading your CV");
          } else if (progress <= 60) {
            setLoadingText("We are checking your CV against Greenhouse/Workday/Ukworkx ATS");
          } else {
            setLoadingText("Our AI and ML algorithms are analyzing your CV");
          }
          
          i++;
          setTimeout(simulateProgress, interval);
        }
      };

      simulateProgress();
      const response = await apiRequest;

      if (response.ok) {
        const data = await response.json();
        window.location.href = `/atschecker/result?name=${encodeURIComponent(
          data.resume.name
        )}&email=${encodeURIComponent(data.resume.email)}&score=${
          data.resume.score
        }&submissionDate=${encodeURIComponent(
          data.resume.submissionDate
        )}&criticism=${encodeURIComponent(
          data.resume.criticism
        )}&detailedResponse=${encodeURIComponent(
          JSON.stringify(data.resume.detailedResponse)
        )}`;
      } else if (response.status === 409) {
        const data = await response.json();
        window.location.href = `/atschecker/result?name=${encodeURIComponent(
          data.existingResume.name
        )}&email=${encodeURIComponent(data.existingResume.email)}&score=${
          data.existingResume.score
        }&submissionDate=${encodeURIComponent(
          data.existingResume.submissionDate
        )}&criticism=${encodeURIComponent(
          data.existingResume.criticism
        )}&detailedResponse=${encodeURIComponent(
          JSON.stringify(data.existingResume.detailedResponse)
        )}`;
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      alert("Error uploading file");
    } finally {
      setIsLoading(false);
    }
  };

  // Content Data
  const stats = [
    {
      icon: RocketLaunchIcon,
      label: "Resumes Analyzed",
      value: "7,000+"
    },
    {
      icon: SparklesIcon,
      label: "Success Rate",
      value: "93%"
    },
    {
      icon: BoltIcon,
      label: "Processing Time",
      value: "<1min"
    }
  ];

  const features = [
    {
      icon: CpuChipIcon,
      title: "AI-Powered Analysis",
      description: "Our advanced AI system, powered by Anthropic's Claude, deep analyzes your resume against UK ATS requirements."
    },
    {
      icon: BuildingOfficeIcon,
      title: "UK-Specific Database",
      description: "Built using data from actual UK companies offering visa sponsorship, ensuring targeted recommendations."
    },
    {
      icon: DocumentCheckIcon,
      title: "Visa-Optimized Scanning",
      description: "Special focus on visa sponsorship requirements and international qualification presentation."
    },
    {
      icon: AcademicCapIcon,
      title: "International Focus",
      description: "Tailored specifically for international students seeking sponsored roles in the UK job market."
    },
    {
      icon: BeakerIcon,
      title: "Technical Expertise",
      description: "Advanced algorithms trained on successful international student applications to UK companies."
    },
    {
      icon: LightBulbIcon,
      title: "Smart Suggestions",
      description: "Intelligent recommendations for presenting international experience to UK employers."
    }
  ];
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <DecorativeCircle />
      
      {isLoading ? (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="w-full max-w-md">
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="mb-4">
                <div className="flex items-center">
                  {loadingProgress <= 25 ? (
                    <span className="loading loading-dots loading-sm sm:loading-lg"></span>
                  ) : loadingProgress <= 60 ? (
                    <span className="loading loading-dots loading-sm sm:loading-lg"></span>
                  ) : (
                    <span className="loading loading-dots loading-sm sm:loading-lg"></span>
                  )}
                  <span className="pl-5 text-sm sm:text-lg font-semibold">
                    {loadingText}
                  </span>
                </div>
              </div>
              <div className="relative pt-1">
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
                  <div
                    style={{ width: `${loadingProgress}%` }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500 transition-all duration-500 ease-in-out"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="container mx-auto px-4 py-12"
        >
          {/* Hero Section */}
          <div className="text-center mb-16 relative">
            <motion.div
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              className="max-w-4xl mx-auto"
            >
              <div className="inline-flex items-center bg-blue-50 rounded-full px-4 py-2 mb-6">
                <ShieldCheckIcon className="h-5 w-5 text-blue-600 mr-2" />
                <span className="text-blue-800 font-medium">100% Free ATS Resume Checker</span>
              </div>
              
              <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
                Free AI-Powered Resume Optimizer for
                <span className="text-blue-600 block mt-2">
                  UK Visa Sponsorship Jobs
                </span>
              </h1>

              <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
                Over 90% of UK companies use ATS to filter applications. Our specialized AI system 
                helps international students get past ATS and secure sponsored roles. 
                100% Free. No signup required.
              </p>

              <div className="grid md:grid-cols-3 gap-6 mt-12">
                {stats.map((stat, index) => (
                  <StatCard key={index} {...stat} />
                ))}
              </div>
            </motion.div>
          </div>

          <div className="grid lg:grid-cols-2 gap-12 items-start mb-24">
            {/* Left Column - Content */}
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="space-y-12"
            >
              {/* Why International Students Section */}
              <div className="prose prose-lg max-w-none text-center">
                <div className="flex justify-center mb-6">
                  <AcademicCapIcon className="h-16 w-16 text-blue-600" />
                </div>
                <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
                  Why International Students Need ATS Optimization
                </h2>
                <p className="text-gray-600 max-w-3xl mx-auto">
                  International students face unique challenges when applying for sponsored roles in the UK. 
                  Standard ATS systems often filter out international candidates due to:
                </p>
                <div className="grid md:grid-cols-2 gap-6 mt-8">
                  <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                    <DocumentTextIcon className="h-8 w-8 text-blue-600 mx-auto mb-4" />
                    <h4 className="font-semibold mb-2">Education Format</h4>
                    <p className="text-gray-600 text-sm">Unconventional education formats and international qualifications</p>
                  </div>
                  <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                    <BriefcaseIcon className="h-8 w-8 text-blue-600 mx-auto mb-4" />
                    <h4 className="font-semibold mb-2">Experience Style</h4>
                    <p className="text-gray-600 text-sm">Different work experience presentation styles</p>
                  </div>
                  <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                    <IdentificationIcon className="h-8 w-8 text-blue-600 mx-auto mb-4" />
                    <h4 className="font-semibold mb-2">Visa Requirements</h4>
                    <p className="text-gray-600 text-sm">Visa sponsorship requirements in applications</p>
                  </div>
                  <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                    <GlobeAltIcon className="h-8 w-8 text-blue-600 mx-auto mb-4" />
                    <h4 className="font-semibold mb-2">Location Details</h4>
                    <p className="text-gray-600 text-sm">Non-UK address and contact information</p>
                  </div>
                </div>
              </div>

              {/* How Our AI Technology Works Section */}
              <div className="bg-white rounded-xl p-8 md:p-12 shadow-lg border border-gray-100 text-center">
                <div className="flex justify-center mb-6">
                  <CpuChipIcon className="h-16 w-16 text-blue-600" />
                </div>
                <h3 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6">
                  How Our AI Technology Works
                </h3>
                <p className="text-gray-600 mb-10 max-w-3xl mx-auto">
                  We've processed over 100,000 resumes through UK's leading ATS systems to understand 
                  exactly what makes them tick. Our AI-powered system provides:
                </p>
                <div className="grid md:grid-cols-2 gap-8">
                  <div className="flex flex-col items-center p-6 rounded-xl bg-gray-50">
                    <SparklesIcon className="h-8 w-8 text-blue-600 mb-4" />
                    <p className="text-gray-600 font-medium">Real-time keyword optimization for visa sponsorship roles</p>
                  </div>
                  <div className="flex flex-col items-center p-6 rounded-xl bg-gray-50">
                    <ClipboardDocumentCheckIcon className="h-8 w-8 text-blue-600 mb-4" />
                    <p className="text-gray-600 font-medium">Format verification for UK ATS compatibility</p>
                  </div>
                  <div className="flex flex-col items-center p-6 rounded-xl bg-gray-50">
                    <AcademicCapIcon className="h-8 w-8 text-blue-600 mb-4" />
                    <p className="text-gray-600 font-medium">International qualification mapping to UK standards</p>
                  </div>
                  <div className="flex flex-col items-center p-6 rounded-xl bg-gray-50">
                    <DocumentMagnifyingGlassIcon className="h-8 w-8 text-blue-600 mb-4" />
                    <p className="text-gray-600 font-medium">Visa sponsorship language optimization</p>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Right Column - Upload Box */}
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="lg:sticky lg:top-24"
            >
              <form onSubmit={handleSubmit} className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8">
                <div className="text-center mb-8">
                  <h2 className="text-2xl font-bold text-gray-900">
                    Get Your Free ATS Score
                  </h2>
                  <p className="text-gray-600 mt-2">
                    Optimized for international students • Instant results
                  </p>
                </div>

                <div className="border-2 border-dashed border-gray-300 rounded-xl p-8 text-center hover:border-blue-500 transition-colors">
                  <label className="cursor-pointer block">
                    <input
                      type="file"
                      id="file-upload"
                      name="file-upload"
                      className="hidden"
                      onChange={handleFileChange}
                      accept=".pdf,.docx"
                    />
                    <ArrowUpTrayIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <span className="mt-4 block text-sm font-medium text-gray-600">
                      Drop your resume here or click to upload
                    </span>
                    <span className="mt-2 block text-xs text-gray-500">
                      Supported formats: PDF, DOCX (Max 5MB)
                    </span>
                  </label>
                </div>

                {selectedFile && (
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="mt-4 flex items-center justify-center space-x-2 text-sm text-gray-600"
                  >
                    <DocumentTextIcon className="h-5 w-5" />
                    <span>{selectedFile.name}</span>
                  </motion.div>
                )}

                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={!selectedFile}
                  className={`mt-6 w-full py-3 px-4 rounded-lg text-white font-medium transition-colors
                    ${selectedFile 
                      ? 'bg-blue-600 hover:bg-blue-700' 
                      : 'bg-gray-400 cursor-not-allowed'}`}
                >
                  Analyze My Resume
                </motion.button>

                <p className="mt-4 text-center text-sm text-gray-500">
                  100% Free • No Credit Card Required
                </p>
              </form>
            </motion.div>
          </div>

          {/* Features Grid */}
          <div className="mb-24">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
              Advanced Features for International Students
            </h2>
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="grid md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </motion.div>
          </div>

          {/* Testimonials Section */}
          <div>
            <Testimonials />
          </div>
        </motion.div>
      )}
    </div>
  );
}