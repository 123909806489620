import AtsReport from "../features/atschecker/AtsReport";
import React, { useState } from 'react';
import ContactForm from "../features/contactform/ContactForm.js"




function AtsReportPage() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');

  const handleContactFormOpen = (action) => {
    setSelectedAction(action);
    setShowContactForm(true);
  };

  const handleContactFormClose = () => {
    setShowContactForm(false);
    setSelectedAction('');
  };
  return (
    <>
    <AtsReport onContactFormOpen={handleContactFormOpen} />
    {showContactForm && (
        <ContactForm
          onClose={handleContactFormClose}
          selectedAction={selectedAction}
        />
      )}
    </>
  )
}

export default AtsReportPage