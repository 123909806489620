import React from 'react';
import { motion } from 'framer-motion';
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Tooltip,
  Legend,
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from 'recharts';
import { ChartPie, TrendingUp } from 'lucide-react';

const InteractiveSummaryGraph = ({ detailedResponse, score }) => {
  // Process the data for radar chart (main metrics)
  const radarData = [
    {
      category: "ATS Score",
      value: score * 20, // Convert 5-scale to percentage
      fullMark: 100
    },
    {
      category: "Keyword Usage",
      value: Math.min(100, (detailedResponse.find(item => item.goodkeywords)?.goodkeywords.split(", ").length || 0) * 10),
      fullMark: 100
    },
    {
      category: "Word Count",
      value: Math.min(100, Math.max(0, ((detailedResponse.find(item => item.wordcount)?.wordcount || 0) / 800) * 100)),
      fullMark: 100
    },
    {
      category: "Spelling",
      value: Math.max(0, 100 - (detailedResponse.find(item => item.spellingmistakes)?.spellingmistakes.split(", ").length || 0) * 10),
      fullMark: 100
    },
    {
      category: "Content Quality",
      value: Math.max(0, 100 - (detailedResponse.find(item => item.fillerwordsused)?.fillerwordsused.split(", ").length || 0) * 10),
      fullMark: 100
    }
  ];

  // Process data for UK market metrics
  const ukMarketData = [
    {
      name: "Market Fit",
      score: parseFloat(detailedResponse.find(item => item.sponsoredscore2)?.sponsoredscore2 || 0),
      benchmark: 85
    },
    {
      name: "Visa Eligibility",
      score: parseFloat(detailedResponse.find(item => item.sponsoredscore)?.sponsoredscore || 0),
      benchmark: 80
    },
    {
      name: "Education",
      score: parseFloat(detailedResponse.find(item => item.sponsoredscore3)?.sponsoredscore3 || 0),
      benchmark: 75
    },
    {
      name: "Cultural Fit",
      score: parseFloat(detailedResponse.find(item => item.sponsoredscore4)?.sponsoredscore4 || 0),
      benchmark: 70
    }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 shadow-lg rounded-lg border border-gray-200">
          <p className="font-semibold text-gray-900">{label || payload[0].payload.category}</p>
          <p className="text-blue-600 mt-1">Score: {payload[0].value.toFixed(1)}%</p>
          {payload.length > 1 && payload[1] && (
            <p className="text-gray-600 mt-1">Benchmark: {payload[1].value}%</p>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-8"
    >
      {/* Main Metrics Radar Chart */}
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          <ChartPie className="w-6 h-6 text-blue-600" />
          <h3 className="text-xl font-bold text-gray-900">Core Resume Metrics</h3>
        </div>
        <div className="w-full h-96">
          <ResponsiveContainer width="100%" height="100%">
            <RadarChart data={radarData}>
              <PolarGrid stroke="#e5e7eb" />
              <PolarAngleAxis 
                dataKey="category" 
                tick={{ fill: '#4b5563', fontSize: 12 }}
              />
              <PolarRadiusAxis 
                angle={30} 
                domain={[0, 100]} 
                tick={{ fill: '#4b5563' }}
              />
              <Radar
                name="Your Score"
                dataKey="value"
                stroke="#2563eb"
                fill="#3b82f6"
                fillOpacity={0.6}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* UK Market Metrics Chart */}
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          <TrendingUp className="w-6 h-6 text-blue-600" />
          <h3 className="text-xl font-bold text-gray-900">UK Market Compatibility</h3>
        </div>
        <div className="w-full h-80">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart data={ukMarketData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, 100]} />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="score" fill="#3b82f6" name="Your Score" />
              <Line 
                type="monotone" 
                dataKey="benchmark" 
                stroke="#ef4444" 
                strokeWidth={2} 
                name="Industry Benchmark"
                dot={false}
              />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>

      
    </motion.div>
  );
};

export default InteractiveSummaryGraph;