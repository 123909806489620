import React, { useRef } from "react";
import { useLocation, Navigate } from "react-router-dom";
import RadialProgress from "./RadialProgress.js";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import WordCloud from "./WordCloud";
import { motion } from "framer-motion";
import { Printer, FileText } from "lucide-react";
import InteractiveSummaryGraph from"./InteractiveSummaryGraph"; // Add this import


// Print functionality
const handlePrint = () => {
  window.print();
};

export default function AtsReport() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const score = parseFloat(searchParams.get("score"));
  const detailedResponse = JSON.parse(searchParams.get("detailedResponse"));
  const reportRef = useRef(null);

  // Check if required data is present
  if (!name || !detailedResponse) {
    return <Navigate to="/atschecker" replace />;
  }

  const getScoreStatus = () => {
    if (!score) {
      return {
        text: "N/A",
        color: "bg-gray-50 text-gray-700 ring-gray-600/20",
      };
    } else if (score < 2.2) {
      return { text: "Low", color: "bg-red-50 text-red-700 ring-red-600/20" };
    } else if (score < 3.6) {
      return {
        text: "Average",
        color: "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
      };
    } else {
      return {
        text: "High",
        color: "bg-green-50 text-green-700 ring-green-600/20",
      };
    }
  };

  const scoreStatus = getScoreStatus();

  return (
    <div className="min-h-screen overflow-hidden bg-gray-50">
      {/* Print Button - Fixed Position */}
      <motion.button
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handlePrint}
        className="fixed bottom-6 right-6 z-50 flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-700 transition-colors duration-200 no-print"
      >
        <Printer className="w-5 h-5" />
        <span>Download PDF</span>
      </motion.button>
  
      {/* Main Content */}
      <div id="report-content" className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8" ref={reportRef}>
        {/* Header Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-12 text-center"
        >
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-4">
            ATS Analysis Report
          </h1>
          <p className="text-lg text-gray-600">
            Detailed Resume Analysis for {name}
          </p>
        </motion.div>
  
        {/* Score Card */}
        {/* Enhanced Score Card */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.2 }}
  className="bg-white rounded-xl shadow-lg overflow-hidden"
>
  <div className="bg-gradient-to-r from-gray-500 to-gray-600 px-6 py-4">
    <h3 className="text-white text-xs font-semibold">This report is generated by Ukworkx ATS Checker and Resume Analyzer. © 2025 Ukworkx. All rights reserved.
    Unauthorized reproduction, distribution, or commercial use of this report is strictly prohibited. This report is provided for personal use only and may not be sold, resold, or used for commercial purposes. Any violation of these terms may result in legal action.</h3>
  </div>
  <dl className="flex flex-wrap items-center p-6 bg-white">
    <div className="flex-auto">
      <dt className="text-sm font-medium text-gray-500">Overall Score</dt>
      <dd className="mt-1 text-3xl font-bold text-gray-900 flex items-baseline">
        {score ? score.toFixed(1) : "N/A"}
        <span className="ml-2 text-sm font-medium text-gray-500">/ 5.0</span>
      </dd>
    </div>
    <div className="mt-4 w-full sm:mt-0 sm:w-auto">
      <dt className="sr-only">Status</dt>
      <dd className={`inline-flex items-center rounded-full px-4 py-2 text-sm font-medium ${
        scoreStatus.text === 'High' 
          ? 'bg-green-100 text-green-700 ring-green-600/20' 
          : scoreStatus.text === 'Average'
          ? 'bg-yellow-100 text-yellow-700 ring-yellow-600/20'
          : 'bg-red-100 text-red-700 ring-red-600/20'
      }`}>
        <span className="relative flex h-2 w-2 mr-2">
          <span className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
            scoreStatus.text === 'High' 
              ? 'bg-green-400' 
              : scoreStatus.text === 'Average'
              ? 'bg-yellow-400'
              : 'bg-red-400'
          }`}></span>
          <span className={`relative inline-flex rounded-full h-2 w-2 ${
            scoreStatus.text === 'High' 
              ? 'bg-green-500' 
              : scoreStatus.text === 'Average'
              ? 'bg-yellow-500'
              : 'bg-red-500'
          }`}></span>
        </span>
        {scoreStatus.text}
      </dd>
    </div>
  </dl>
  <div className="bg-gray-50 px-6 py-4">
    <p className="text-sm text-gray-600">
      {score < 2.2 ? (
        "Your resume needs significant improvements to pass ATS systems effectively."
      ) : score < 3.6 ? (
        "Your resume is moderately ATS-friendly but has room for improvement."
      ) : (
        "Your resume is well-optimized for ATS systems."
      )}
    </p>
  </div>
</motion.div>
  
        {/* Detailed Analysis Section */}
        <div className="space-y-12">
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-white rounded-xl shadow-sm ring-1 ring-gray-900/5 p-6"
          >
            <h2 className="text-xl font-bold text-gray-900 mb-6">Detailed Analysis</h2>
            <dl className="space-y-8">
            {detailedResponse
  .filter(
    (result) =>
      result.section && result.observation && result.recommendation
  )
  .map((result, index) => (
    <motion.div
      key={index}
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: 0.1 * index }}
      className="grid grid-cols-1 gap-4 lg:grid-cols-12 lg:gap-8 pb-8 border-b border-gray-200 last:border-0"
    >
      <dt className="text-base font-semibold text-gray-900 lg:col-span-5 flex items-start gap-2">
        <ExclamationTriangleIcon className="w-6 h-6 flex-shrink-0 text-amber-500" />
        {result.section}
      </dt>
      <dd className="lg:col-span-7 space-y-4">
        <div className="space-y-2">
          <h4 className="text-sm underline font-bold text-gray-900">
          Impact on Your Application
          </h4>
          <motion.p 
            className="text-base text-gray-600 block" 
            initial={{ backgroundSize: '0% 3px' }}
            animate={{ backgroundSize: '100% 3px' }}
            transition={{ 
              duration: 1.5,
              delay: 0.2 * index + 0.5,
              ease: "easeInOut"
            }}
            style={{
              backgroundImage: 'linear-gradient(to right, #FED7D7, #FED7D7)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0 100%',
              display: 'inline'
            }}
          >
            {result.observation}
          </motion.p>
        </div>
        <div className="space-y-2">
          <h4 className="text-sm underline font-bold text-gray-900">
            How do I fix it?
          </h4>
          <motion.p 
            className="text-base text-gray-600 block"
            initial={{ backgroundSize: '0% 3px' }}
            animate={{ backgroundSize: '100% 3px' }}
            transition={{ 
              duration: 1.5,
              delay: 0.2 * index + 1,
              ease: "easeInOut"
            }}
            style={{
              backgroundImage: 'linear-gradient(to right, #BBF7D0, #BBF7D0)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0 100%',
              display: 'inline'
            }}
          >
            {result.recommendation}
          </motion.p>
        </div>
      </dd>
    </motion.div>
  ))}
            </dl>
          </motion.section>
          {/* Keywords Analysis Section */}
          <motion.section
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.4 }}
  className="bg-white rounded-xl shadow-sm ring-1 ring-gray-900/5 p-6"
>
  <h2 className="text-xl font-bold text-gray-900 mb-6">Content Analysis</h2>
  
  {/* Keywords Section */}
  <div className="mb-12">
    <div className="flex justify-center mb-4">
      <RadialProgress
        percentage={Math.min(
          100,
          (detailedResponse
            .find((item) => item.goodkeywords)
            ?.goodkeywords.split(", ").length || 0) * 10
        )}
      />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 text-center mb-4">
      Impactful Keywords
    </h3>
    
    <div className="bg-blue-50 rounded-lg p-6 mb-6">
      <h4 className="text-sm font-bold text-blue-900 mb-3 inline-block border-b-2 border-blue-900">
        Why are keywords crucial for your resume?
      </h4>
      <p className="text-sm text-blue-800 mb-4">
        Keywords act as the bridge between your resume and ATS systems. They are essential because:
      </p>
      <ul className="text-sm text-blue-800 space-y-2 list-disc list-inside mb-4">
        <li>They help your resume pass ATS screening systems</li>
        <li>They demonstrate industry knowledge to recruiters</li>
        <li>They align your skills with job requirements</li>
        <li>They increase your resume's visibility in applicant databases</li>
      </ul>
      <div className="bg-blue-100 rounded p-3">
        <span className="font-semibold">Optimal Range:</span> 8-12 industry-specific keywords
      </div>
    </div>
    
    {(() => {
      const keywords =
        detailedResponse
          .find((item) => item.goodkeywords)
          ?.goodkeywords.split(", ") || [];
      const keywordCount = keywords.length;

      let message = "";
      let color = "";
      if (keywordCount < 8) {
        message = "Your resume needs more relevant keywords to improve ATS compatibility.";
        color = "text-amber-600";
      } else if (keywordCount <= 12) {
        message = "Your resume has an optimal number of keywords for ATS systems.";
        color = "text-green-600";
      } else {
        message = "Consider reducing keywords to avoid keyword stuffing.";
        color = "text-red-600";
      }

      return (
        <p className={`text-center ${color} font-medium mb-6`}>{message}</p>
      );
    })()}
    
    <div className="mt-6">
      <WordCloud
        keywords={
          detailedResponse
            .find((item) => item.goodkeywords)
            ?.goodkeywords.split(", ") || []
        }
      />
    </div>
  </div>

  {/* Word Count Section */}
  <motion.div className="border-t border-gray-200 pt-8 mb-12">
    <div className="flex justify-center mb-4">
      <div className="text-center">
        <span className="text-5xl font-bold text-gray-900">
          {(() => {
            const wordCountData = detailedResponse.find((item) => item.wordcount)?.wordcount;
            
            if (!wordCountData) return 0;
            
            // Handle case where wordcount is a string like "approximately 500 words"
            if (typeof wordCountData === 'string') {
              // Extract numbers from the string
              const matches = wordCountData.match(/\d+/);
              if (matches) {
                return Number(matches[0]);
              }
            }
            
            // If it's already a number, just return it
            return Number(wordCountData) || 0;
          })()}
        </span>
        <span className="text-lg text-gray-600 ml-2">Words</span>
      </div>
    </div>
    
    {/* Rest of your word count section code remains the same */}
    <div className="bg-blue-50 rounded-lg p-6 mb-6">
      <h4 className="text-sm font-bold text-blue-900 mb-3 inline-block border-b-2 border-blue-900">
        Why does word count matter?
      </h4>
      <p className="text-sm text-blue-800 mb-4">
        Your resume's length directly impacts its effectiveness. The right word count ensures:
      </p>
      <ul className="text-sm text-blue-800 space-y-2 list-disc list-inside mb-4">
        <li>Optimal readability for both ATS and human recruiters</li>
        <li>Sufficient detail while maintaining engagement</li>
        <li>Professional presentation of your experience</li>
        <li>Better chances of holding recruiter attention</li>
      </ul>
      <div className="bg-blue-100 rounded p-3">
        <span className="font-semibold">Ideal Range:</span> 400-800 words for most professional resumes
      </div>
    </div>
    
    {(() => {
      const wordCountData = detailedResponse.find((item) => item.wordcount)?.wordcount;
      let wordCount = 0;
      
      if (typeof wordCountData === 'string') {
        const matches = wordCountData.match(/\d+/);
        if (matches) {
          wordCount = Number(matches[0]);
        }
      } else {
        wordCount = Number(wordCountData) || 0;
      }

      let message = "";
      let color = "";
      let icon = "";
      
      if (wordCount < 400) {
        message = "Your resume is too concise and may be missing important details.";
        color = "text-amber-600";
        icon = "⚠️";
      } else if (wordCount <= 800) {
        message = "Your resume length is perfect for ATS and human readability.";
        color = "text-green-600";
        icon = "✓";
      } else {
        message = "Your resume is too long and may lose recruiter interest.";
        color = "text-red-600";
        icon = "⚠️";
      }

      return (
        <div className="text-center mb-6">
          <p className={`${color} font-medium flex items-center justify-center gap-2`}>
            <span>{icon}</span>
            {message}
          </p>
        </div>
      );
    })()}
  </motion.div>

  {/* Buzzwords Section */}
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.6 }}
    className="border-t border-gray-200 pt-8"
  >
    <div className="flex justify-center mb-4">
      <RadialProgress
        percentage={Math.min(
          100,
          (detailedResponse
            .find((item) => item.goodbuzzwords)
            ?.goodbuzzwords.split(", ").length || 0) * 10
        )}
      />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 text-center mb-4">
      Industry Buzzwords Impact
    </h3>
    
    <div className="bg-blue-50 rounded-lg p-6 mb-6">
      <h4 className="text-sm font-bold text-blue-900 mb-3 inline-block border-b-2 border-blue-900">
        Why do industry buzzwords matter?
      </h4>
      <p className="text-sm text-blue-800 mb-4">
        Strategic use of industry buzzwords demonstrates your expertise. They are valuable because:
      </p>
      <ul className="text-sm text-blue-800 space-y-2 list-disc list-inside mb-4">
        <li>They show familiarity with industry trends and practices</li>
        <li>They help establish professional credibility</li>
        <li>They match what recruiters are looking for</li>
        <li>They boost your resume's relevance in ATS rankings</li>
      </ul>
      <div className="bg-blue-100 rounded p-3">
        <span className="font-semibold">Recommended Range:</span> 6-10 relevant industry buzzwords
      </div>
    </div>
    
    <div className="mt-6">
      <WordCloud
        keywords={
          detailedResponse
            .find((item) => item.goodbuzzwords)
            ?.goodbuzzwords.split(", ") || []
        }
      />
    </div>
  </motion.div>
</motion.section>
{/* Improvement Areas Section */}
<motion.section
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 0.7 }}
  className="bg-white rounded-xl shadow-sm ring-1 ring-gray-900/5 p-6"
>
  <h2 className="text-xl font-bold text-gray-900 mb-6">Areas for Improvement</h2>

  {/* Introduction Box */}
  <div className="bg-blue-50 rounded-lg p-6 mb-8">
    <h4 className="text-sm font-bold text-blue-900 mb-3 inline-block border-b-2 border-blue-900">
      Why are these improvements important?
    </h4>
    <p className="text-sm text-blue-800 mb-4">
      Addressing these areas can significantly improve your resume's effectiveness:
    </p>
    <ul className="text-sm text-blue-800 space-y-2 list-disc list-inside">
      <li>Higher ATS system acceptance rates</li>
      <li>Better first impressions with recruiters</li>
      <li>Clearer communication of your skills</li>
      <li>Increased chances of interview invitations</li>
    </ul>
  </div>

  {/* Words to Remove Section */}
  <div className="mb-12">
    <div className="flex justify-center mb-4">
      <RadialProgress
        percentage={Math.max(
          0,
          100 -
            (detailedResponse
              .find((item) => item.wordsyoushouldremove)
              ?.wordsyoushouldremove.split(", ").length || 0) *
              10
        )}
      />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 text-center mb-4">
      Words to Improve
    </h3>
    
    <div className="bg-amber-50 rounded-lg p-4 mb-6">
      <h4 className="text-sm font-bold text-amber-900 mb-2">Why remove these words?</h4>
      <p className="text-sm text-amber-800">
        Certain words can weaken your resume's impact. Removing them helps because:
        <ul className="mt-2 space-y-1 list-disc list-inside">
          <li>They may be seen as cliché or overused</li>
          <li>They could trigger negative ATS responses</li>
          <li>They might not effectively showcase your achievements</li>
          <li>They can make your resume appear less professional</li>
        </ul>
      </p>
    </div>
    
    {(() => {
      const wordsToRemove =
        detailedResponse
          .find((item) => item.wordsyoushouldremove)
          ?.wordsyoushouldremove.split(", ") || [];
      const wordCount = wordsToRemove.length;

      let message = "";
      let color = "";
      let icon = "";
      
      if (wordCount === 0 || wordsToRemove[0] === "0") {
        message = "Excellent! No problematic words found in your resume.";
        color = "text-green-600";
        icon = "✓";
      } else {
        message = `Found ${wordCount} word${wordCount === 1 ? '' : 's'} that need attention`;
        color = "text-amber-600";
        icon = "⚠️";
      }

      return (
        <div className={`text-center ${color} font-medium mb-6 flex items-center justify-center gap-2`}>
          <span>{icon}</span>
          <span>{message}</span>
        </div>
      );
    })()}

    <div className="mt-6">
      <WordCloud
        keywords={
          detailedResponse
            .find((item) => item.wordsyoushouldremove)
            ?.wordsyoushouldremove.split(", ") || []
        }
      />
    </div>
  </div>

  {/* Spelling Mistakes Section */}
  <motion.div className="border-t border-gray-200 pt-8 mb-12">
    <div className="flex justify-center mb-4">
      <RadialProgress
        percentage={(() => {
          const spellingData = detailedResponse
            .find((item) => item.spellingmistakes)
            ?.spellingmistakes;
          
          // Handle various "no errors" responses
          const noErrorResponses = [
            "0",
            "none",
            "none detected",
            "no spelling errors",
            "no errors",
            "no mistakes",
            "none found",
            "",
            null,
            undefined
          ];
          
          // Check if response indicates no errors
          if (!spellingData || noErrorResponses.includes(spellingData.toLowerCase?.()) || 
              (Array.isArray(spellingData) && spellingData.length === 0)) {
            return 100;
          }
          
          // Calculate percentage based on errors
          const errors = spellingData.split(", ").filter(error => 
            error && !noErrorResponses.includes(error.toLowerCase())
          );
          
          return Math.max(0, 100 - (errors.length * 10));
        })()}
      />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 text-center mb-4">
      Spelling Accuracy
    </h3>
    
    <div className="bg-amber-50 rounded-lg p-4 mb-6">
      <h4 className="text-sm font-bold text-amber-900 mb-2">Why is spelling crucial?</h4>
      <p className="text-sm text-amber-800">
        Correct spelling is essential for several reasons:
        <ul className="mt-2 space-y-1 list-disc list-inside">
          <li>ATS systems may reject misspelled keywords</li>
          <li>Shows attention to detail and professionalism</li>
          <li>Maintains credibility with recruiters</li>
          <li>Ensures clear communication of your skills</li>
        </ul>
      </p>
    </div>
    
    {(() => {
      const spellingData = detailedResponse
        .find((item) => item.spellingmistakes)
        ?.spellingmistakes;

      const noErrorResponses = [
        "0",
        "none",
        "none detected",
        "no spelling errors",
        "no errors",
        "no mistakes",
        "none found",
        "",
        null,
        undefined
      ];

      const hasNoErrors = !spellingData || 
        noErrorResponses.includes(spellingData.toLowerCase?.()) ||
        (Array.isArray(spellingData) && spellingData.length === 0);

      if (hasNoErrors) {
        return (
          <div className="text-center text-green-600 font-medium mb-6 flex items-center justify-center gap-2">
            <span>✓</span>
            <span>Perfect spelling! No errors detected.</span>
          </div>
        );
      }

      const errors = spellingData.split(", ").filter(error => 
        error && !noErrorResponses.includes(error.toLowerCase())
      );
      
      return (
        <div className="text-center text-amber-600 font-medium mb-6 flex items-center justify-center gap-2">
          <span>⚠️</span>
          <span>Found {errors.length} spelling error{errors.length === 1 ? '' : 's'} to address</span>
        </div>
      );
    })()}

    {(() => {
      const spellingData = detailedResponse
        .find((item) => item.spellingmistakes)
        ?.spellingmistakes;

      const noErrorResponses = [
        "0",
        "none",
        "none detected",
        "no spelling errors",
        "no errors",
        "no mistakes",
        "none found",
        "",
        null,
        undefined
      ];

      const hasErrors = spellingData && 
        !noErrorResponses.includes(spellingData.toLowerCase?.()) &&
        (!Array.isArray(spellingData) || spellingData.length > 0);

      if (hasErrors) {
        const errors = spellingData.split(", ").filter(error => 
          error && !noErrorResponses.includes(error.toLowerCase())
        );
        
        if (errors.length > 0) {
          return (
            <div className="mt-6">
              <WordCloud keywords={errors} />
            </div>
          );
        }
      }
      
      return null;
    })()}
  </motion.div>

  {/* Filler Words Section */}
  <motion.div className="border-t border-gray-200 pt-8">
    <div className="flex justify-center mb-4">
      <RadialProgress
        percentage={Math.max(
          0,
          100 -
            (detailedResponse
              .find((item) => item.fillerwordsused)
              ?.fillerwordsused.split(", ").length || 0) *
              10
        )}
      />
    </div>
    <h3 className="text-lg font-semibold text-gray-900 text-center mb-4">
      Filler Words Impact
    </h3>
    
    <div className="bg-amber-50 rounded-lg p-4 mb-6">
      <h4 className="text-sm font-bold text-amber-900 mb-2">Why avoid filler words?</h4>
      <p className="text-sm text-amber-800">
        Filler words can diminish your resume's effectiveness:
        <ul className="mt-2 space-y-1 list-disc list-inside">
          <li>They take up valuable space without adding value</li>
          <li>They can make achievements sound vague</li>
          <li>They reduce the impact of your experience</li>
          <li>They may indicate lack of confidence or clarity</li>
        </ul>
      </p>
    </div>

    {(() => {
      const fillerWords =
        detailedResponse
          .find((item) => item.fillerwordsused)
          ?.fillerwordsused.split(", ") || [];
      const wordCount = fillerWords.length;

      let message = "";
      let color = "";
      let icon = "";

      if (wordCount === 0 || fillerWords[0] === "0") {
        message = "Great work! No unnecessary filler words found.";
        color = "text-green-600";
        icon = "✓";
      } else {
        message = `Found ${wordCount} filler word${wordCount === 1 ? '' : 's'} to improve`;
        color = "text-amber-600";
        icon = "⚠️";
      }

      return (
        <div className={`text-center ${color} font-medium mb-6 flex items-center justify-center gap-2`}>
          <span>{icon}</span>
          <span>{message}</span>
        </div>
      );
    })()}

    {detailedResponse.find((item) => item.fillerwordsused)?.fillerwordsused !== "0" && (
      <div className="mt-6">
        <WordCloud
          keywords={
            detailedResponse
              .find((item) => item.fillerwordsused)
              ?.fillerwordsused.split(", ") || []
          }
        />
      </div>
    )}
  </motion.div>
</motion.section>
{/* UK Market Fit Scores Section */}
<motion.section
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ delay: 1.0 }}
  className="bg-white rounded-xl shadow-sm ring-1 ring-gray-900/5 p-6"
>
  <h2 className="text-xl font-bold text-gray-900 mb-6">UK Market Compatibility</h2>
  
  {/* Introduction Box */}
  <div className="bg-blue-50 rounded-lg p-6 mb-8">
    <h4 className="text-sm font-bold text-blue-900 mb-3 inline-block border-b-2 border-blue-900">
      Why is UK market compatibility important?
    </h4>
    <p className="text-sm text-blue-800 mb-4">
      Your resume's alignment with UK market expectations significantly impacts your job search success:
    </p>
    <ul className="text-sm text-blue-800 space-y-2 list-disc list-inside mb-4">
      <li>Increases chances of passing UK-specific ATS systems</li>
      <li>Helps employers understand your qualifications better</li>
      <li>Demonstrates cultural awareness and market understanding</li>
      <li>Improves visa sponsorship opportunities</li>
    </ul>
  </div>

  <div className="grid grid-cols-1 gap-6">
  {/* Visa Sponsorship Likelihood */}
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 1.1 }}
    className="bg-white rounded-lg shadow-sm p-6"
  >
    <div className="flex flex-col sm:flex-row sm:items-center gap-4">
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          Visa Sponsorship Likelihood
        </h3>
        <p className="text-sm text-gray-600 mb-4 sm:mb-0">
          Measures alignment with UK visa sponsorship requirements
        </p>
      </div>
      <div className="w-full sm:w-64">
        <div className="flex items-center gap-3">
          <div className="flex-1 h-4 bg-gray-100 rounded-full overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{ 
                width: `${detailedResponse.find((item) => item.sponsoredscore)?.sponsoredscore || 0}%` 
              }}
              transition={{ duration: 1, delay: 1.2 }}
              className="h-full bg-blue-600 rounded-full"
            />
          </div>
          <span className="text-sm font-medium text-gray-900 min-w-[3rem]">
            {detailedResponse.find((item) => item.sponsoredscore)?.sponsoredscore || 0}%
          </span>
        </div>
      </div>
    </div>
    <div className="mt-4 bg-blue-50 rounded-lg p-3">
      <ul className="text-sm text-blue-800 space-y-1 list-disc list-inside">
        <li>Skill level and experience</li>
        <li>Qualification relevance</li>
        <li>Industry demand</li>
      </ul>
    </div>
  </motion.div>

  {/* UK Job Market Fit */}
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 1.2 }}
    className="bg-white rounded-lg shadow-sm p-6"
  >
    <div className="flex flex-col sm:flex-row sm:items-center gap-4">
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          UK Job Market Fit
        </h3>
        <p className="text-sm text-gray-600 mb-4 sm:mb-0">
          Evaluates alignment with UK job market demands
        </p>
      </div>
      <div className="w-full sm:w-64">
        <div className="flex items-center gap-3">
          <div className="flex-1 h-4 bg-gray-100 rounded-full overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{ 
                width: `${detailedResponse.find((item) => item.sponsoredscore2)?.sponsoredscore2 || 0}%` 
              }}
              transition={{ duration: 1, delay: 1.3 }}
              className="h-full bg-blue-600 rounded-full"
            />
          </div>
          <span className="text-sm font-medium text-gray-900 min-w-[3rem]">
            {detailedResponse.find((item) => item.sponsoredscore2)?.sponsoredscore2 || 0}%
          </span>
        </div>
      </div>
    </div>
    <div className="mt-4 bg-blue-50 rounded-lg p-3">
      <ul className="text-sm text-blue-800 space-y-1 list-disc list-inside">
        <li>Industry-specific skills</li>
        <li>UK market experience</li>
        <li>Professional certifications</li>
      </ul>
    </div>
  </motion.div>

  {/* Education Equivalency */}
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 1.3 }}
    className="bg-white rounded-lg shadow-sm p-6"
  >
    <div className="flex flex-col sm:flex-row sm:items-center gap-4">
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          UK Education Equivalency
        </h3>
        <p className="text-sm text-gray-600 mb-4 sm:mb-0">
          Assesses qualification comparison to UK standards
        </p>
      </div>
      <div className="w-full sm:w-64">
        <div className="flex items-center gap-3">
          <div className="flex-1 h-4 bg-gray-100 rounded-full overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{ 
                width: `${detailedResponse.find((item) => item.sponsoredscore3)?.sponsoredscore3 || 0}%` 
              }}
              transition={{ duration: 1, delay: 1.4 }}
              className="h-full bg-blue-600 rounded-full"
            />
          </div>
          <span className="text-sm font-medium text-gray-900 min-w-[3rem]">
            {detailedResponse.find((item) => item.sponsoredscore3)?.sponsoredscore3 || 0}%
          </span>
        </div>
      </div>
    </div>
    <div className="mt-4 bg-blue-50 rounded-lg p-3">
      <ul className="text-sm text-blue-800 space-y-1 list-disc list-inside">
        <li>Degree recognition</li>
        <li>Professional qualifications</li>
        <li>Specialized certifications</li>
      </ul>
    </div>
  </motion.div>

  {/* Cultural Fit */}
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: 1.4 }}
    className="bg-white rounded-lg shadow-sm p-6"
  >
    <div className="flex flex-col sm:flex-row sm:items-center gap-4">
      <div className="flex-1">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">
          UK Cultural Fit
        </h3>
        <p className="text-sm text-gray-600 mb-4 sm:mb-0">
          Evaluates understanding of UK workplace culture
        </p>
      </div>
      <div className="w-full sm:w-64">
        <div className="flex items-center gap-3">
          <div className="flex-1 h-4 bg-gray-100 rounded-full overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{ 
                width: `${detailedResponse.find((item) => item.sponsoredscore4)?.sponsoredscore4 || 0}%` 
              }}
              transition={{ duration: 1, delay: 1.5 }}
              className="h-full bg-blue-600 rounded-full"
            />
          </div>
          <span className="text-sm font-medium text-gray-900 min-w-[3rem]">
            {detailedResponse.find((item) => item.sponsoredscore4)?.sponsoredscore4 || 0}%
          </span>
        </div>
      </div>
    </div>
    <div className="mt-4 bg-blue-50 rounded-lg p-3">
      <ul className="text-sm text-blue-800 space-y-1 list-disc list-inside">
        <li>Communication style</li>
        <li>Work etiquette</li>
        <li>Business practices</li>
      </ul>
    </div>
  </motion.div>
</div>

{/* Interactive Performance Dashboard */}
<div className="mt-12 space-y-6">
  <div className="border-t border-gray-200 pt-8">
    <h3 className="text-lg font-semibold text-gray-900 text-center mb-6">
      Interactive Performance Dashboard
    </h3>
    
    <div className="bg-blue-50 rounded-lg p-6 mb-8">
      <h4 className="text-sm font-bold text-blue-900 mb-3 inline-block border-b-2 border-blue-900">
        Why is this visualization helpful?
      </h4>
      <p className="text-sm text-blue-800 mb-4">
        This interactive radar chart provides a comprehensive view of your resume's performance across all key metrics:
      </p>
      <ul className="text-sm text-blue-800 space-y-2 list-disc list-inside">
        <li>Compare different aspects of your resume at a glance</li>
        <li>Identify areas of strength and improvement</li>
        <li>Understand how different metrics relate to each other</li>
        <li>Track your overall ATS compatibility</li>
      </ul>
    </div>

    <InteractiveSummaryGraph detailedResponse={detailedResponse} score={score} />
  </div>
</div>

  {/* Summary Box */}
  <div className="mt-8 bg-gray-50 rounded-lg p-4 text-center">
    <p className="text-sm text-gray-600">
      These scores help identify areas where you can improve your resume's alignment with UK market expectations.
      Focus on areas with lower scores to increase your chances of success in the UK job market. The radar chart shows your core resume metrics, while the bar chart compares your UK market compatibility scores against industry benchmarks. Hover over any element for detailed information.


    </p>
  </div>
</motion.section>

        {/* Closing Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.5 }}
          className="mt-8 text-center"
        >
          <div className="inline-flex items-center gap-2 text-gray-600">
            <FileText className="w-5 h-5" />
            <span className="sm:text-md text-xs">Report generated on {new Date().toLocaleDateString()}, Powered by Ukworkx</span>
          </div>
        </motion.div>
      </div>
    </div>
  </div>
);
}
